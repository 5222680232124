
import {
  defineComponent,
  PropType,
  SetupContext,
  ref,
  onMounted,
  watch,
} from 'vue';
import { TabProp } from '@/types/Tab';

export default defineComponent({
  props: {
    tabs: {
      type: Array as PropType<TabProp[]>,
      default: () => [],
    },
    modelValue: [String, Number],
  },
  emits: ['update:modelValue'],
  setup: (_props, { emit, attrs }: SetupContext) => {
    const currentTab = ref<any>(null);

    onMounted(() => {
      currentTab.value = attrs.tab;
    });

    watch(
      () => attrs.tab,
      () => (currentTab.value = attrs.tab),
    );

    const changeTab = (t: string | number) => {
      currentTab.value = t;
      emit('update:modelValue', currentTab.value);
    };

    return { currentTab, changeTab };
  },
});
