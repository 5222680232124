<template>
  <div class="flex flex-wrap items-center border-b">
    <div v-for="(tab, i) in tabs" :key="i">
      <div
        class="mr-5 text-sm text-invest-primary pb-3 cursor-pointer"
        :class="
          currentTab === tab.value
            ? 'font-bold border-b-2 border-invest-purple'
            : 'font-normal'
        "
        @click="changeTab(tab.value)"
      >
        {{ tab.title }}
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {
  defineComponent,
  PropType,
  SetupContext,
  ref,
  onMounted,
  watch,
} from 'vue';
import { TabProp } from '@/types/Tab';

export default defineComponent({
  props: {
    tabs: {
      type: Array as PropType<TabProp[]>,
      default: () => [],
    },
    modelValue: [String, Number],
  },
  emits: ['update:modelValue'],
  setup: (_props, { emit, attrs }: SetupContext) => {
    const currentTab = ref<any>(null);

    onMounted(() => {
      currentTab.value = attrs.tab;
    });

    watch(
      () => attrs.tab,
      () => (currentTab.value = attrs.tab),
    );

    const changeTab = (t: string | number) => {
      currentTab.value = t;
      emit('update:modelValue', currentTab.value);
    };

    return { currentTab, changeTab };
  },
});
</script>
